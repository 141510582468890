<template>
  <div class="mem-view benefits-view">
    <div class="mem-title">{{ $t("benefits") }}</div>

    <!-- global pass -->
    <div class="benefits-card card-global-pass">
      <div class="card-title mem-font--benefits-view__card-title">
        {{ $t("unbreakable_global_pass") }}
      </div>
      <div class="card-description" v-if="!activePass">
        <div class="description-line">
          <i class="material-icons">done</i>
          <span>{{ $t("global_pass_banner_text_line_1") }}</span>
        </div>
        <div class="description-line">
          <i class="material-icons">done</i>
          <span>{{ $t("global_pass_banner_text_line_2") }}</span>
        </div>
        <div class="description-line">
          <i class="material-icons">done</i>
          <span>{{ $t("global_pass_banner_text_line_3") }}</span>
        </div>
      </div>
      <div class="card-buttons">
        <mem-button v-if="!activePass" @click="openPassPage">{{
          $t("commit")
        }}</mem-button>
        <mem-button
          v-if="activePass"
          :btn-type="'secondary-dark'"
          @click="$router.push({ name: 'UnbreakablePassPage' })"
          >{{ $t("details") }}</mem-button
        >
      </div>
      <img src="@/assets/upass_card_bg.png" class="card-image" alt="" />
      <img
        src="@/assets/upass_card_bg_mobile.png"
        class="card-image-mobile"
        alt=""
      />
    </div>
    <!-- spartan plus -->
    <div class="benefits-card card-spartan-plus" v-if="showSpartanPlus">
      <div class="card-title">
        <img src="@/assets/spartan_edge_logo.svg" alt="sparta edge" />
      </div>
      <div
        class="card-description"
        v-if="
          !userSubscription || (userSubscription && !userSubscription.isActive)
        "
      >
        <div class="description-line">
          <i class="material-icons">done</i>
          <span>{{ $t("s_plus_banner_text_line_1") }}</span>
        </div>
        <div class="description-line">
          <i class="material-icons">done</i>
          <span>{{ $t("s_plus_banner_text_line_2") }}</span>
        </div>
        <div class="description-line">
          <i class="material-icons">done</i>
          <span>{{ $t("s_plus_banner_text_line_3") }}</span>
        </div>
      </div>
      <div class="card-buttons">
        <mem-button
          v-if="userSubscription && userSubscription.isActive"
          :btn-type="'secondary-dark'"
          @click="$router.push({ name: 'SpartanPlusPage' })"
          >{{ $t("details") }}</mem-button
        >
        <template v-else>
          <mem-button @click="$router.push({ name: 'subscription' })">{{
            $t("upgrade")
          }}</mem-button>
          <a
            href="https://www.spartan.com/pages/spartan-plus-membership"
            class="button-link"
            >{{ $t("learn_more") }}</a
          >
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "BenefitsView",
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  computed: {
    ...mapGetters(["userData", "userSubscription"]),
    activePass() {
      // return false;
      return this.userData.upass;
    },
    showSpartanPlus() {
      const SUPPORTED_COUNTRIES = ["US"];
      return SUPPORTED_COUNTRIES.includes(this.userData.spartan_country_code);
    },
  },
  methods: {
    openPassPage() {
      let windowReference = window.open();
      windowReference.location = "https://unbreakable.spartan.com/";
    },
  },
  async mounted() {},
};
</script>
<style lang="scss" scoped>
.benefits-view {
  .mem-title {
    @include elementMarginBottom("M");
  }
  .benefits-card {
    position: relative;
    overflow: hidden;

    background-color: #121212;
    min-height: 245px;
    max-width: 670px;
    padding-top: 42px;
    padding-bottom: 42px;
    padding-left: 46px;
    margin-bottom: 16px;
    @media screen and (max-width: $mobile) {
      min-height: 242px;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 24px;
      margin-bottom: 8px;
    }
    .card-title {
      margin-bottom: 20px;
      @media screen and (max-width: $mobile) {
        margin-bottom: 12px;
      }
    }
    .card-description {
      margin-bottom: 20px;
      @media screen and (max-width: $mobile) {
        margin-bottom: 16px;
      }
      .description-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        text-transform: capitalize;
        @media screen and (max-width: $mobile) {
          font-size: 12px;
          max-width: calc(192px + 20px);
        }
        i {
          margin-right: 6px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .card-buttons {
      button {
        height: 35px;

        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0.04em;
      }
    }
    .card-title,
    .card-description,
    .card-buttons {
      position: relative;
      z-index: 10;
    }
    .card-image {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
      @media screen and (max-width: $mobile) {
        display: none;
      }
    }
    .card-image-mobile {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
      @media screen and (max-width: $mobile) {
        display: flex;
      }
    }
  }
  .card-global-pass {
    .card-image {
      width: 392px;
      height: 264px;
    }
    .card-image-mobile {
      width: 235px;
      height: 242px;
    }
  }
  .card-spartan-plus {
    background: linear-gradient(-58deg, rgba(18, 18, 18, 0) 49%, #121212 49%)
        no-repeat,
      url("https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/account/spartan_edge_bg.webp")
        no-repeat 260% 50%/87%;
    position: relative;
    @media screen and (max-width: $mobile) {
      background: linear-gradient(-58deg, rgba(18, 18, 18, 0) 49%, #121212 49%)
          no-repeat,
        url("https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/account/spartan_edge_bg.webp")
          no-repeat 85px 50% / cover;
    }
    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
    }
    .card-buttons {
      @media screen and (max-width: $mobile) {
        display: grid;
        grid-template-columns: max-content;
      }
      .button-link {
        margin-left: 16px;

        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        text-decoration: none;
        @media screen and (max-width: $mobile) {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
    .background-image {
    }
  }
}
</style>